var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addNewData)}}},[_c('div',{staticClass:"form-wrapper",staticStyle:{"width":"auto","margin-top":"15px"}},[_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"key"},[_c('p',{staticClass:"input-label"},[_vm._v(" Name "),_c('span',{staticClass:"field-required"},[_vm._v("*")])])]),_c('div',{staticClass:"value input-expanded"},[_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":_vm.data.name == 'Super Admin' || _vm.data.name == 'Admin',"expanded":"","placeholder":"name"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('p',{staticClass:"notif has-text-danger required"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"key"},[_c('p',{staticClass:"input-label"},[_vm._v(" Description "),_c('span',{staticClass:"field-required"},[_vm._v("*")])])]),_c('div',{staticClass:"value input-expanded"},[_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea","name":"description"},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}},[_vm._v(" "+_vm._s(_vm.data.description || '')+" ")])],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"key"},[_c('p',{staticClass:"input-label"},[_vm._v(" Access "),_c('span',{staticClass:"field-required"},[_vm._v("*")])])]),_c('div',{staticClass:"value input-expanded"},[_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"name":"access","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-select',{attrs:{"multiple":"","native-size":_vm.access.length,"name":"access"},model:{value:(_vm.data.access),callback:function ($$v) {_vm.$set(_vm.data, "access", $$v)},expression:"data.access"}},_vm._l((_vm.access),function(acc){return _c('option',{key:acc.id,domProps:{"value":acc.id}},[_vm._v(" "+_vm._s(acc.name)+" - "+_vm._s(acc.parentId == 0 ? 'Menu' : 'Sub Menu')+" ")])}),0)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Press CTRL + Click for select multiple ")])]}}],null,true)})],1)]),_c('b-button',{staticStyle:{"font-weight":"bold"},attrs:{"native-type":"submit","type":"is-hcc","expanded":""}},[_vm._v(" "+_vm._s(_vm.editData == null ? 'Add' : 'Edit')+" ")])],1)])])]}}])}),_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }