<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage" @input="onPerPageChange">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="roles && roles.data ? roles.data : []"
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="roles && roles.total ? roles.total : 0"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="index"
        width="20"
        numeric
        label="No"
        v-slot="props"
      >
        {{ props.index + roles.from }}
      </b-table-column>
      <b-table-column field="menu" label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="access" label="Access" sortable v-slot="props">
        {{ props.row.access.map(e => e.access.name).toString() }}
      </b-table-column>
      <b-table-column field="action" centered label="Action" v-slot="props">
        <template>
          <b-button
            type="icon-button"
            icon-left="pencil-alt"
            v-if="props.row.name != 'Super Admin'"
            @click="
              editRole(
                props.row.id,
                props.row.name,
                props.row.description,
                props.row.access.map(e => e.accessId)
              )
            "
          ></b-button>
          <!-- <b-button
            type="icon-button"
            icon-left="trash"
            @click="deletePopup(props.row.id)"
          ></b-button> -->
        </template>
      </b-table-column>
      <template slot="bottom-left">
        {{ roles && roles.from ? roles.from : 0 }}
        -
        {{ roles && roles.to ? roles.to : 0 }}
        from
        {{ roles && roles.total ? roles.total : 0 }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoading: true,
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'created_at',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      link: '',
      message: {
        text: '',
        type: '',
      },
      isModalPreviewActive: false,
      isModalLinkActive: false,
      access: [],
    }
  },
  mounted() {
    this.fetchAccess()
  },
  methods: {
    ...mapActions({
      fetchRole: 'newRole/fetchRole',
      deleteRoleData: 'newRole/deleteRole',
      fetchAll: 'newAccess/fetchAll',
    }),
    reload() {
      this.fetchData(
        this.perPage,
        this.currentPage,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
    // For search role
    searchRole: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }
    }, 500),

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Role',
        message: `Are you sure want to delete this role?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteRole(id),
      })
    },

    // For delete role
    async deleteRole(id) {
      this.isLoading = true

      try {
        await this.deleteRoleData(id)

        this.success('Delete Role Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      )

      this.isLoading = false
    },

    // For fetching data
    async fetchData(perPage, page, sortField, sortOrder, search) {
      let data = {
        perPage: perPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        search: search,
      }

      this.isLoadingTable = false
      try {
        await this.fetchRole(data)
      } catch (err) {
        this.danger(err)
      }
      this.isLoadingTable = true
    },

    editRole(id, name, description, access) {
      this.$emit('edit-role', { id, name, description, access })
    },

    async fetchAccess() {
      const response = await this.fetchAll()
      this.access = response.data

      this.fetchData(
        this.perPage,
        this.currentPage,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },

    // For Page Change
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },

    // For Sorting Data
    onSort(field, order) {
      if (field != 'access') {
        this.sortField = field
        this.sortOrder = order
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  watch: {
    search: function(val) {
      this.searchRole(val)
    },
  },
  computed: {
    ...mapGetters({
      roles: 'newRole/getNewRole',
    }),
  },
}
</script>
<style scoped>
.modal-copy-link {
  width: 500px !important;
}
</style>
